import React, { useEffect, useState } from "react";
import Description from "./Description";
import { Button, TextField } from "@mui/material";
import * as api from "../../utils/api/apiClient";
import { toast } from "react-toastify";
import Loading from "../Loading";
import { normalPrice } from "../../utils/PriceCalculate";

function ProductVariant({
  productId,
  handleClose,
  data,
  WhatDoYouWant = "Create",
}) {
  // TODO: If Refresh page than stable this page...
  const [loading, setLoading] = useState(false);
  const [variantFormData, setVariantFormData] = useState({
    name: "",
    description: "",
    discount: "",
    price: "",
    quantity: "",
    product_code: "",
  });

  const handleChange = (event) => {
    setVariantFormData({
      ...variantFormData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    if (data) {
      setVariantFormData({
        ...variantFormData,
        name: data.name,
        description: data.variant_description,
        discount: data.discount,
        price: normalPrice(data.price),
        quantity: data.quantity,
        product_code: data.product_code,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const createVariant = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const data = new FormData();
      data.append("name", variantFormData.name);
      data.append("description", variantFormData.description);
      data.append("discount", variantFormData.discount);
      data.append("price", variantFormData.price);
      data.append("product_id", productId);
      data.append("quantity", variantFormData.quantity);
      data.append("product_code", variantFormData.product_code);

      const res = await api.createVariant(data);
      if (res) {
        setVariantFormData({
          name: "",
          description: "",
          discount: "",
          price: "",
          quantity: "",
          product_code: "",
        });
        toast.success("Variant Created Successfully");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };
  const editVariant = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const res = await api.editVariant(data.variant_id, variantFormData);
      if (res) {
        setVariantFormData({
          name: "",
          description: "",
          discount: "",
          price: "",
          quantity: "",
          product_code: "",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {loading && <Loading />}
      <h1 className="font-medium py-2">Create Variant</h1>
      <form action="#" className="flex flex-col gap-5 w-full">
        <div>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            fullWidth={true}
            size="small"
            color="info"
            value={variantFormData.name}
            required
            name="name"
            onChange={handleChange}
          />
        </div>
        <div className="grid gap-3 grid-cols-1 sm:grid-cols-2">
          <div>
            <TextField
              id="price"
              label="Price"
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              value={variantFormData.price}
              required
              name="price"
              onChange={handleChange}
            />
          </div>
          <div>
            <TextField
              id="discount"
              label="Discount"
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              value={variantFormData.discount}
              required
              name="discount"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="grid gap-3 grid-cols-1 sm:grid-cols-2">
          <div>
            <TextField
              id="product_code"
              label="Product Code"
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              value={variantFormData.product_code}
              required
              name="product_code"
              onChange={handleChange}
            />
          </div>
          <div>
            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              fullWidth={true}
              size="small"
              color="info"
              value={variantFormData.quantity}
              required
              name="quantity"
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <Description
            data={variantFormData.description}
            changeData={(data) =>
              setVariantFormData({ ...variantFormData, description: data })
            }
          />
        </div>
        <div>
          <Button
            disabled={
              !variantFormData.name?.length > 0 ||
              !variantFormData.price.toString()?.length > 0 ||
              !variantFormData.discount.toString()?.length > 0 ||
              !variantFormData.product_code?.length > 0 ||
              !variantFormData.quantity.toString()?.length > 0 ||
              !variantFormData.description?.length > 0
            }
            onClick={(event) => {
              if (data) {
                editVariant(event);
              } else {
                createVariant(event);
              }
              if (handleClose) {
                handleClose();
              }
            }}
            variant="contained"
            disableElevation
          >
            {WhatDoYouWant === "Create" ? "Submit" : "Save"}
          </Button>
        </div>
      </form>
    </>
  );
}

export default ProductVariant;
