import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProductVariant from "../../../components/ProductComponent/ProductVariant";
import { Helmet } from "react-helmet";
import ProductCreatingSteps from "../../../components/ProductCreatingSteps";
import * as api from "../../../utils/api/apiClient";
import Loading from "../../../components/Loading";
import { Button } from "@mui/material";

function CreateVariant() {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const [refresh, setRefresh] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getOneProduct = async () => {
      setLoading(true);
      try {
        const res = await api.getProductById(id);
        if (res) {
          const obj = { ...res.data };
          obj.image_urls.push(res.data.thumbnail);
          setData(obj);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    getOneProduct();
  }, [id, refresh]);

  return (
    <>
      <Helmet>
        <title>Variant Create | Inflection Admin</title>
      </Helmet>
      {loading && <Loading />}
      <div className="pt-2 pr-2 pl-2 sm:pl-0 pb-5">
        <ProductCreatingSteps step={3} />
        <div className="w-full sm:w-8/12 mx-auto relative">
          {/* Edit Product Form */}
          <ProductVariant
            productId={id}
            handleClose={() => setRefresh(!refresh)}
          />
          {data?.variants?.length > 0 && (
            <div className="absolute bottom-0 right-0">
              <Button
                onClick={(event) => {
                  navigate(`/products/${id}`);
                }}
                variant="contained"
                disableElevation
              >
                {"Product Preview"}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateVariant;
