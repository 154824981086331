import React, { useState } from "react";
import * as api from "../../utils/api/apiClient";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { IoClose } from "react-icons/io5";
import Loading from "../Loading";

function ProductImages({ productId, title = "Submit", navigate }) {
  const [loading, setLoading] = useState(false);
  const [image_urls, setImage_urls] = useState([]);
  const [previews, setPreviews] = useState([]);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setImage_urls(selectedFiles);

    // Generate preview URLs
    const newPreviews = selectedFiles.map((file) => URL.createObjectURL(file));
    setPreviews(newPreviews);
  };

  // Cleanup previews when component unmounts
  React.useEffect(() => {
    return () => {
      previews.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [previews]);

  const removeImage = (index) => {
    const newFiles = image_urls.filter((_, i) => i !== index);
    const newPreviews = previews.filter((_, i) => i !== index);
    setImage_urls(newFiles);
    setPreviews(newPreviews);
  };

  const uploadImages = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const data = new FormData();
      image_urls.forEach((element) => {
        data.append("image_urls", element);
      });
      const res = await api.addImageToProduct(productId, data);
      if (res) {
        setImage_urls([]);
        setPreviews([]);
        setLoading(false);
        if (navigate) {
          navigate();
        }
      }
    } catch (error) {
      setLoading(false);
      if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="flex flex-col gap-4">
        <h6 className="font-medium">Add Images</h6>
        <input
          disabled={!productId}
          type="file"
          multiple
          accept="image/*"
          onChange={handleFileChange}
          className="w-full border border-mediumgray border-opacity-50 hover:border-black rounded-md outline-none cursor-pointer p-1"
        />

        {previews.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {previews.map((preview, index) => (
              <div key={index} className="relative group">
                <img
                  src={preview}
                  alt={`Preview ${index + 1}`}
                  className="w-full h-32 object-cover rounded-md"
                />
                <button
                  onClick={() => removeImage(index)}
                  className="absolute top-2 right-2 p-1 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <IoClose className="w-4 h-4 text-gray-700" />
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="text-center">
          <Button
            disabled={image_urls.length === 0}
            onClick={uploadImages}
            variant="contained"
            disableElevation
          >
            {title}
          </Button>
        </div>
      </div>
    </>
  );
}

export default ProductImages;
