import React, { useContext } from "react";
import { IoPower } from "react-icons/io5";
import { FaHashtag } from "react-icons/fa6";
import { FaBox } from "react-icons/fa";
import { LuUsers } from "react-icons/lu";
import { MdMessage, MdDashboard } from "react-icons/md";
import { FaCartArrowDown } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import userPic from "../../assets/profile.png";
import Heading from "../ui/Heading";
import { deleteCookie } from "../../utils/api/cookies";
import { toast } from "react-toastify";
import { logOut } from "../../utils/api/apiClient";
import { AuthContext } from "../../context/authContext";

function Sidebar({ CloseDrawer, children, isCss = false }) {
  const { isLogin, setIsLogin, profileDetails } = useContext(AuthContext);
  const routes = [
    {
      name: "Dashboard",
      path: "/",
      icon: <MdDashboard />,
    },
    {
      name: "Tags",
      path: "/tags",
      icon: <FaHashtag />,
    },
    {
      name: "Categories",
      path: "/categories",
      icon: <BiCategory />,
    },
    {
      name: "Products",
      path: "/products",
      icon: <FaCartArrowDown />,
    },
    {
      name: "Orders",
      path: "/orders",
      icon: <FaBox />,
    },
    {
      name: "Users",
      path: "/users",
      icon: <LuUsers />,
    },
    {
      name: "Review",
      path: "/reviews",
      icon: <MdMessage />,
    },
  ];

  const logout = async () => {
    try {
      const res = await logOut();
      if (res) {
        deleteCookie("access_token");
        deleteCookie("refresh_token");
        toast.success("Logout successfully");
        setIsLogin(false);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {children}
      <div
        className={`w-full h-full flex flex-col gap-2 bg-white ${
          isCss && "z-50 w-52"
        } -translate-y-1 ${!isLogin && "hidden"}`}
      >
        {/* Profile (image & name) */}
        <div className="flex items-center gap-3 bg-white px-2 pt-2">
          <img
            src={
              profileDetails.profile_photo_url
                ? profileDetails.profile_photo_url
                : userPic
            }
            alt="profile"
            className="w-10 h-10 rounded-full"
          />
          <div>
            <p className="text-xs">Hello,</p>
            <h4 className="font-medium">
              {isLogin && profileDetails?.full_name
                ? profileDetails?.full_name
                : "Guest"}
            </h4>
          </div>
        </div>
        <div className="flex flex-col">
          {routes.map((link, index) => (
            <Heading
              key={index}
              clickToCloseDrawer={CloseDrawer}
              icon={link.icon}
              title={link.name}
              link={link.path}
            />
          ))}
          <Heading
            logout={logout}
            clickToCloseDrawer={() => CloseDrawer}
            icon={<IoPower />}
            title={"LOGOUT"}
            link={"#"}
          />
        </div>
      </div>
    </>
  );
}

export default Sidebar;
